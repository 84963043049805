﻿<script>
    import { searchMediator } from "./search.mediator.js";

    export default {
        name: "search-input",
        data() {
            return {
                phrase: ""
            }
        },
        props: ["searchType"],
        mounted() {
        },
        methods: {
        },
        watch: {
            phrase() {
                searchMediator.raise(this.searchType, this.phrase);
            }
        }
    }
</script>