﻿/* eslint-disable import/prefer-default-export */
export const searchMediator = {
    listeners: {},
    state: {
    },
    raise(searchType, phrase) {
        let typeListeners = this.listeners[searchType];
        typeListeners.forEach(x => {
            x(phrase);
        });
    },
    register(searchType, listener) {
        let typeListeners = this.listeners[searchType] || (this.listeners[searchType] = []);
        typeListeners.push(listener);
    },
    deregister(searchType, listener) {
        let typeListeners = this.listeners[searchType];
        if (typeListeners) {
            typeListeners.splice(typeListeners.indexOf(listener), 1);
        }
    }
};
