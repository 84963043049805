<template>
  <div>
    <a
      class="share"
      id="facebook"
      title="Share on Facebook"
      :href="seoUrl"
      @click="facebook()"
    ></a>
    <a class="share" id="linkedin" :href="linkedin" target="_blank"></a>
    <a class="share" id="twitter" :href="twitter" target="_blank"></a>
  </div>
</template>
<script>
export default {
  name: "sharing",
  data() {
    return {
      linkedin: "",
      twitter: ""
    };
  },
  props: ["seoName", "seoDescription", "seoUrl"],
  methods: {
    facebook() {
      window.open(
        "https://www.facebook.com/sharer.php?u=" +
          encodeURIComponent(this.seoUrl) +
          "&t=" +
          encodeURIComponent(this.seoName),
        "sharer",
        "toolbar=0,status=0,width=626,height=436"
      );
      return false;
    }
  },
  mounted() {
    this.linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${
      encodeURIComponent(this.seoUrl)
    }&title=${this.seoName}&summary=${this.seoDescription}&source=${
      encodeURIComponent(this.seoUrl)
    }`;
    this.twitter = `https://twitter.com/share?url=${encodeURIComponent(this.seoUrl)}&text=${
      this.seoName
    }`;
  }
};
</script>