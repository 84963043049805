﻿<script>
    const origHighlight = $.validator.defaults.highlight,
        origUnhighlight = $.validator.defaults.unhighlight;

    Object.assign($.validator.defaults, {
        highlight: function (el, errorClass, validClass) {
            const container = $(el).closest(".form-group, .form-group-custom");
            if (container.length) {
                container.removeClass(validClass).addClass(errorClass);
            }
            origHighlight.apply(this, arguments);
        },
        unhighlight: function (el, errorClass, validClass) {
            const container = $(el).closest(".form-group, .form-group-custom");
            if (container.length) {
                container.removeClass(errorClass).addClass(validClass);
            }
            origUnhighlight.apply(this, arguments);
        },
        errorPlacement: function (error, element) {
            element.closest("div.form-group").append(error);
        },
        invalidHandler: function (event, validator) {
            var count = validator.numberOfInvalids();
            if (count) {
                $("#errorSummary").show();
            } else {
                $("#errorSummary").hide();
            }
        }
    });

    const jqValidateMixin = {
        data: () => {
            return {
                form: null
            };
        },
        mounted() {
            const form = (this.form =
                this.$el.tagName.toLowerCase() === "form"
                    ? this.$el
                    : $("form", this.$el).get(0));
            $.validator.unobtrusive.parse(this.form);
            $("input, select, textarea", this.form).on("keyup", function () {
                $(this).valid();
            });
        },
        methods: {
            invalid() {
                console.log("validating");
                return !$(this.form).valid();
            }
        }
    };

    const jqValidateComponent = {
        mixins: [jqValidateMixin]
    };

    const jqValidateDirective = {
        name: 'jq-validate',
        inserted(el, binding) {
            $.validator.unobtrusive.parse(el);
            binding.value({
                invalid: function () {
                    return !$(el).valid();
                }
            });
        }
    };

    export default jqValidateMixin;
</script>
