/* eslint-disable import/prefer-default-export */
export const observer = {
    listeners: [],
    state: {
        activeItemId: null
    },
    setActiveId(id) {
        this.state.activeItemId = id;
        this.listeners.forEach(x => {
            x(id);
        });
    },
    register(listener) {
        this.listeners.push(listener);
    },
    deregister(listener) {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
};
