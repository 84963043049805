﻿<template>
    <div class="search-result-preview" v-if="result.company">
        <div>
            {{ localization.resultsFor }}
            <span class="phrase">{{ phrase }}</span>
        </div>
        <a class="show-all" :href="urls.search + '?q=' + phrase">{{
            localization.seeAll
        }}</a>
        <div v-show="result.company.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.company }}
                ({{ result.company.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=company&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.company }}</a
            >
            <div
                class="content-wrapper"
                v-for="company in result.company.Items"
            >
                <a :href="company.Uri">{{ company.Data.name }}</a>
            </div>
        </div>
        <div v-show="result.contact.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.contact }}
                ({{ result.contact.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=contact&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.contact }}</a
            >
            <div
                class="content-wrapper"
                v-for="contact in result.contact.Items"
            >
                <a :href="contact.Uri"
                    >{{ contact.Data.firstname }} {{ contact.Data.lastname }}</a
                >
            </div>
        </div>
        <div v-show="result.arrangement.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.events }}
                ({{ result.arrangement.Total }})
            </h2>
            <a
                class="show-all"
                :href="urls.search + '?t=arrangement&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.events }}</a
            >
            <div
                class="content-wrapper"
                v-for="evt in result.arrangement.Items"
            >
                <a :href="evt.Uri">{{ evt.Title }}</a>
                <p>
                    {{ new Date(evt.Data.tid).toLocaleString() }},
                    {{ evt.Data.sted }}
                </p>
                <p>{{ evt.Data.ingress }}</p>
                <p v-html="evt.Highlights"></p>
            </div>
        </div>
        <div v-show="result.arrangement.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.eventarchive }}
                ({{ result.arrangementarkiv.Total }})
            </h2>
            <a
                class="show-all"
                :href="urls.search + '?t=arrangementarkiv&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.eventarchive }}</a
            >
            <div
                class="content-wrapper"
                v-for="evt in result.arrangementarkiv.Items"
            >
                <a :href="evt.Uri">{{ evt.Title }}</a>
                <p>
                    {{ new Date(evt.Data.tid).toLocaleString() }},
                    {{ evt.Data.sted }}
                </p>
                <p>{{ evt.Data.ingress }}</p>
                <p v-html="evt.Highlights"></p>
            </div>
        </div>
        <div v-show="result.nyttnavn.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.newNames }}
                ({{ result.nyttnavn.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=nyttnavn&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.newName }}</a
            >
            <div v-for="item in result.nyttnavn.Items">
                <a :href="item.Uri">{{ item.Title }}</a>
                <p v-html="item.Highlights"></p>
            </div>
        </div>
        <div v-show="result.nyhetssak.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.news }}
                ({{ result.nyhetssak.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=nyhetssak&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.news }}</a
            >
            <div v-for="item in result.nyhetssak.Items">
                <a :href="item.Uri">{{ item.Title }}</a>
                <p v-html="item.Highlights"></p>
            </div>
        </div>
        <div v-show="result.magasin.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.magazine }}
                ({{ result.magasin.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=magasin&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.magazine }}</a
            >
            <div v-for="item in result.magasin.Items">
                <a :href="item.Uri">{{ item.Title }}</a>
                <p v-html="item.Highlights"></p>
            </div>
        </div>
        <div v-show="result.andre.Items.length">
            <h2>
                {{ localization.hits }}
                {{ localization.other }}
                ({{ result.andre.Total }})
            </h2>
            <a class="show-all" :href="urls.search + '?t=andre&q=' + phrase"
                >{{ localization.seeAllIn }} {{ localization.other }}</a
            >
            <div v-for="item in result.andre.Items">
                <a :href="item.Uri">{{ item.Title }}</a>
                <p v-html="item.Highlights"></p>
            </div>
        </div>
        <a class="show-all" :href="urls.search + '?q=' + phrase">{{
            localization.seeAll
        }}</a>
    </div>
</template>
<script>
const localization = {
    'en-US': {
        resultsFor: 'Showing results for',
        hits: 'Hits in',
        events: 'upcoming events',
        eventarchive: 'previous events',
        company: 'companies',
        contact: 'members',
        magazine: 'Rosenkilden',
        newNames: 'new names',
        news: 'news',
        other: 'other documents',
        seeAll: 'See all hits',
        seeAllIn: 'See all hits in '
    },
    'nb-NO': {
        resultsFor: 'Viser treff for',
        hits: 'Treff i',
        events: 'kommende arrangement',
        eventarchive: 'tidligere arrangement',
        company: 'bedrifter',
        contact: 'kontakter',
        magazine: 'Rosenkilden',
        newNames: 'nytt om navn',
        news: 'nyheter',
        other: 'andre dokumenter',
        seeAll: 'Se alle treff',
        seeAllIn: 'Se alle treff i'
    }
};

export default {
    name: 'search-result-preview',
    props: ['type', 'culture'],
    inject: ['searchMediator', 'http', 'urls'],
    data() {
        return {
            phrase: '',
            result: {},
            localization: {}
        };
    },
    created() {
        this.localization = localization[this.culture];
    },
    mounted() {
        this.searchMediator.register(this.type, this.search);
    },
    methods: {
        search(phrase) {
            if (this.phrase !== phrase) {
                this.phrase = phrase;
                if (this.phrase.length >= 3) {
                    this.http
                        .get(
                            `${this.urls.searchApi
                }?phrase=${
                phrase
                }&ctx=${
                this.urls.searchContext}`
                        )
                        .then(result => {
                            this.result = result;
                        });
                } else {
                    this.result = {};
                }
            }
        }
    }
};
</script>
