﻿<template>
    <div class="attendee-wrapper">
        <button
            type="button"
            class="has-icon btn btn-primary"
            v-on:click.prevent="toggle()"
        >
            <span class="icon pluss"></span>
            {{ localization.addAttendees }}
        </button>
        <div class="attende-form" v-if="showAttendeeForm">
            <div
                v-for="(attendee, index) in command.Attendees"
                :key="index"
                :index="index"
            >
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label :for="'Email' + index">
                                {{ localization.email }}
                            </label>
                            <input
                                type="email"
                                class="form-control"
                                :name="'Attendees[' + index + '].Email'"
                                :id="'Email' + index"
                                v-model="attendee.Email"
                                required
                                pattern="@emailPattern"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label :for="'Fornavn' + index">
                                {{ localization.firstname }}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                :name="'Attendees[' + index + '].FirstName'"
                                :id="'Fornavn' + index"
                                v-model="attendee.FirstName"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label :for="'Etternavn' + index">
                                {{ localization.lastname }}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                :name="'Attendees[' + index + '].LastName'"
                                :id="'Etternavn' + index"
                                v-model="attendee.LastName"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label :for="'Telefon' + index">
                                {{ localization.phone }}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                :name="'Attendees[' + index + '].Phone'"
                                :id="'Telefon' + index"
                                v-model="attendee.Phone"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <select-options :options="command.Options"
                                        :order-line="attendee"
                                        :prefix="'Attendees[' + index + '].'"
                                        :comment-placeholder="localization.optionsComment"
                                        :currency="localization.currency"></select-options>
                    </div>
                </div>

                <div class="addActions">
                    <button
                        type="button"
                        class="btn btn-success"
                        v-if="index + 1 === command.Attendees.length"
                        v-on:click.prevent="addAttendee()"
                    >
                        {{ localization.add }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger"
                        v-on:click.prevent="removeAttendee(index)"
                    >
                        {{ localization.remove }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-default"
                        v-if="index + 1 === command.Attendees.length"
                        v-on:click.prevent="clearInput(index)"
                    >
                        {{ localization.reset }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const localization = {
    'en-US': {
        addAttendees: 'Add attendees',
        email: 'E-mail:',
        firstname: 'First name:',
        lastname: 'Last name:',
        phone: 'Phone:',
        add: 'Add',
        remove: 'Remove',
        reset: 'Reset',
        currency: "NOK ",
        optionsComment: 'Comments (allergies etc.)'
    },
    'nb-NO': {
        addAttendees: 'Legg til deltakere',
        email: 'E-post:',
        firstname: 'Fornavn:',
        lastname: 'Etternavn:',
        phone: 'Telefon:',
        add: 'Legg til',
        remove: 'Fjern',
        reset: 'Nullstill',
        currency: "kr. ",
        optionsComment: 'Kommentarer, f.eks. allergier o.l.'
    }
};

export default {
    name: 'add-attendee',
    props: ['command', 'culture'],
    data() {
        return {
            showAttendeeForm: false,
            localization: {}
        };
    },
    watch: {},
    methods: {
        toggle() {
            this.showAttendeeForm = !this.showAttendeeForm;
            if (this.showAttendeeForm && this.command.Attendees.length === 0) {
                this.addAttendee();
            }
        },
        addAttendee() {
            let checkEmptyValues = [];
            let attendee = this.command.Attendees[
                this.command.Attendees.length - 1
            ];
            if (attendee) {
                checkEmptyValues = Object.values(attendee).filter(
                    value => value === null
                );
                if (checkEmptyValues.length >= 1) return;
            }
            this.command.Attendees.push({
                Email: null,
                FirstName: null,
                LastName: null,
                Phone: null,
                SelectedOptions: []
            });
        },
        removeAttendee(index) {
            this.command.Attendees.splice(index, 1);
            this.showAttendeeForm = this.command.Attendees.length > 0;
        },
        clearInput(index) {
            Object.keys(this.command.Attendees[index]).forEach(key => {
                this.command.Attendees[index][key] = null;
            });
        }
    },
    created() {
        this.localization = localization[this.culture];
    },
    mounted() {}
};
</script>
