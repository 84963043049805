﻿<template>
    <div class="alert alert-warning">
        {{localization.not}} {{ firstName }} {{ lastName }}?
        <a href="javascript:void(0)" @click="reset()">{{localization.clickHere}}</a>
    </div>
</template>
<script>

    const localization = {
        "en-US": {
            "not": "Not",
            "clickHere": "Click here."
        },
        "nb-NO": {
            "not": "Ikke",
            "clickHere": "Klikk her."
        },
    }

    export default {
        name: 'reset-user',
        props: ['firstName', 'lastName', 'culture'],
        data() {
            return {
                localization: {}
            };
        },
        inject: ['hsqFactory', 'location'],
        created() {
            this.localization = localization[this.culture];
        },
        methods: {
            reset() {
                this.hsqFactory().push(['revokeCookieConsent']);
                setTimeout(function () { this.location.href = this.location.pathname; }, 1000)
                //this.location.href = this.location.pathname + "?clearcookie=true";
            }
        }
    };
</script>
