﻿import Vue from 'vue/dist/vue.min.js'; // Only option to remove development warning was to use .min.jss
import EnrollForm from './enrollment/enroll.component.vue';
import ToggleWrapper from './ui/toggle.component.vue';
import ToggleTarget from './ui/toggle-target.component.vue';
import Sharing from './ui/sharing.vue';
import ResetUser from './enrollment/reset-user.component.vue';
import AddAttendee from './enrollment/add-attendee.component.vue';
import SelectOptions from './enrollment/select-options.component.vue';
import PushIdentity from './enrollment/push-identity.component.vue';
import JqValidateDirective from './validation/validation.mixin.vue';
import Carousel from './ui/carousel.component.vue';
import CarouselList from './ui/carousel-list.component.vue';
import SearchInput from './search/search-input.component.vue';
import SearchResultPreview from './search/search-result-preview.component.vue';
import { searchMediator } from './search/search.mediator';

const urls = {
    searchApi: '/umbraco/api/sitesearch/search'
};
window.urls = urls;
window.snInit = window.snInit || (window.snInit = []);

Vue.component('select-options', SelectOptions);

window.vm = new Vue({
    el: '#outer-wrapper',
    components: {
        EnrollForm,
        ResetUser,
        ToggleWrapper,
        ToggleTarget,
        AddAttendee,
        PushIdentity,
        Carousel,
        CarouselList,
        SearchInput,
        SearchResultPreview,
        Sharing
    },
    directives: {
        JqValidateDirective
    },
    provide: {
        urls,
        searchMediator,
        // http: axios
        http: {
            get(url) {
                return fetch(url).then(response => response.json());
            },
            post(url, data) {
                return fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(response => response.json());
            }
        },
        hsqFactory: () => window._hsq,
        location: window.location
    },
    created() {},
    mounted() {
        window.snInit.forEach(x => x());
    }
});
