<template>
    <div>
        <div v-for="(option, index) in orderLine.SelectedOptions" v-bind:key="option.Description">
            <input type="hidden" :name="skuId(index)" v-model="option.Sku" />
            <input type="hidden" :name="descriptionId(index)" v-model="option.Description" />
            <div class="col-xs-12 form-group">
                <label :for="checkboxId(index)">
                    <input type="hidden" :name="selectedId(index)" :id="selectedId(index)" v-model="option.Selected" />
                    <input type="checkbox" :name="checkboxId(index)" :id="checkboxId(index)" v-model="option.Selected" :disabled="options[index].Mandatory" value="true" />
                    {{option.Description}},
                    {{currency}} {{options[index].Price}},-
                </label>
            </div>
            <div class="col-xs-12 form-group">
                <input type="text" class="form-control" :name="commentId(index)" :id="commentId(index)" v-model="option.Comment" v-if="options[index].AllowComment" :placeholder="commentPlaceholder" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['options', 'orderLine', 'prefix', 'commentPlaceholder', 'currency'],
    methods: {
        skuId(index) {
            return this.prefix + "SelectedOptions[" + index + "].Sku";
        },
        descriptionId(index) {
            return this.prefix + "SelectedOptions[" + index + "].Description";
        },
        checkboxId(index) {
            return this.prefix + "SelectedOptions[" + index + "].SelectedCheckbox";
        },
        selectedId(index) {
            return this.prefix + "SelectedOptions[" + index + "].Selected";
        },
        commentId(index) {
            return this.prefix + "SelectedOptions[" + index + "].Comment";
        }
    },
    created: function() {
        if (this.orderLine.SelectedOptions.length === 0) {
            this.options.forEach(option => {
                this.orderLine.SelectedOptions.push({
                    Sku: option.Sku,
                    Description: option.Description,
                    Selected: option.Mandatory,
                    Comment: ""
                })
            });
        }
    }
}
</script>