﻿<script>
import { observer } from './observer';

export default {
    name: 'carousel',
    data() {
        return {
            currentActive: -1,
            hover: false,
            activeItem: observer.state,
            wrapperElement: null
        };
    },
    methods: {
        carouselHover() {},
        activeChanged() {
            if (this.currentActive > -1) {
                this.wrapperElement.children[
                    this.currentActive
                ].classList.remove('active');
            }
            this.currentActive = observer.state.activeItemId;
            this.wrapperElement.children[this.currentActive].classList.add(
                'active'
            );
        },
        setFirstActiveItem() {
            this.wrapperElement.children[0].classList.add('active');
            this.currentActive = 0;
        }
    },
    computed: {
        activeId() {
            return this.activeItem.activeItemId;
        }
    },
    mounted() {
        this.wrapperElement = this.$el;
        this.setFirstActiveItem();
        observer.register(this.activeChanged);
    },
    destroyed() {
        observer.deregister(this.activeChanged);
    }
};
</script>
