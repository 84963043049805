﻿<script>
    export default {
        name: 'push-identity',
        props: ["email", "refresh"],
        created: function() {
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push([
                "identify", {
                    email: this.email
                }
            ]);
            console.log("should refresh for identity " + this.refresh);
            if (this.refresh === "true" || this.refresh === true) {
                $(document).ready(() => {
                    setTimeout(() => location.reload(true), 5000);
                });
            }
        }
    }
</script>