<script>
import { observer } from './observer';

export default {
    name: 'carousel-list',
    data() {
        return {
            hover: false,
            activeItem: observer.state,
            wrapperElement: null,
            changeEvent: null
        };
    },
    methods: {
        startCarousel(listItem) {
            let i = listItem;
            this.changeEvent = setInterval(() => {
                observer.setActiveId(i);
                i = i < this.carouselItems - 1 ? i + 1 : 0;
            }, 2000);
        },
        setFirstActiveItem() {
            this.wrapperElement.children[0].classList.add('active');
        },
        carouselHover(index) {
            this.hover = true;
            observer.setActiveId(index);
        }
    },
    computed: {
        activeId() {
            return this.activeItem.activeItemId;
        }
    },
    watch: {
        activeId(currentActive, oldActive) {
            if (oldActive != null) {
                this.wrapperElement.children[oldActive].classList.remove(
                    'active'
                );
            }
            this.wrapperElement.children[currentActive].classList.add('active');
        },
        hover() {
            if (this.hover) {
                clearInterval(this.changeEvent);
                this.changeEvent = null;
            } else {
                this.startCarousel(this.activeId);
            }
        }
    },
    mounted() {
        this.wrapperElement = this.$el;
        this.carouselItems = this.wrapperElement.children.length;
        this.setFirstActiveItem();
        this.startCarousel(0);
    },
    created() {
        observer.setActiveId(0);
    },
    beforeDestroy() {
        clearInterval(this.changeEvent);
    }
};
</script>
