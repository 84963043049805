<script>
    /* eslint-disable func-names */
    /* eslint-disable prefer-destructuring */

    import ResetUser from './reset-user.component.vue';
    import JqValidateMixin from '../validation/validation.mixin.vue';
    import AddAttendee from './add-attendee.component.vue';
    import FuzzySet from 'fuzzyset.js';

    const fuseOptions = {
        keys: ['Name'],
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1
    }

    function calculateSimilarity(enhet, command) {
        let parts = enhet.Name.split(' ');
        let extParts = command.Company.split(' ');
        let fuzzy = new FuzzySet(), fullFuzzy = new FuzzySet();
        fullFuzzy.add(enhet.Name);
        let matches = fullFuzzy.get(command.Company) || [];
        parts.forEach(x => fuzzy.add(x));
        extParts.forEach(x => { matches = matches.concat(fuzzy.get(x) || [[0, '']]) });
        let sum = 0;
        matches.forEach(x => sum += x[0]);
        let avg = sum / Math.max(matches.length, 1);
        return avg;
    }

    export default {
        name: 'enroll-form',
        props: ['initial-data'],
        inject: ['http'],
        mixins: [JqValidateMixin],
        components: {
            ResetUser,
            AddAttendee
        },
        data() {
            return {
                processing: false,
                lastSearch: '',
                companySearch: '',
                displayOptions: {
                    showCompanySearch: false,
                    haveEmail: false,
                    haveCompany: false
                },
                discountedPrice: 'none',
                discountMessage: '',
                command: {
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    Phone: '',
                    IsPrivate: false,
                    OrganizationNumber: '',
                    Company: '',
                    EventId: '',
                    EventDate: null,
                    EventName: '',
                    BillingAddress: '',
                    BillingPostalCode: '',
                    BillingPostalArea: '',
                    CreateUser: false,
                    Password: '',
                    RepeatPassword: '',
                    IsMember: false,
                    PaymentType: '',
                    DiscountCode: '',
                    Pricing: {
                        IsFree: true,
                        Member: 0,
                        NonMember: 0
                    },
                    Attendees: [],
                    ExistingAttendeeId: "",
                    SelectedExistingCompanyId: 0,
                    Options:[],
                    SelectedOptions:[]
                },
                errors: [],
                companyMatches: [],
                currentEmail: ''
            };
        },
        computed: {
            price() {
                let enrollPrice = !isNaN(this.discountedPrice)
                    ? this.discountedPrice
                    : this.command.Pricing.IsFree
                        ? 0
                        : this.command.Pricing.Standard
                            ? this.command.Pricing.Standard
                            : this.command.IsMember
                                ? this.command.Pricing.Member
                                : this.command.Pricing.NonMember;
                
                let total = (this.command.Attendees.length + 1) * enrollPrice;

                let orderLines = [this.command].concat(this.command.Attendees)
                for(let ol = 0; ol<orderLines.length; ol++) {
                    let orderLine = orderLines[ol];
                    for(let i = 0; i<orderLine.SelectedOptions.length; i++) {
                        if (orderLine.SelectedOptions[i].Selected) {
                            total += this.command.Options[i].Price;
                        }
                    }
                }

                if (this.command.PaymentType === "invoice" && enrollPrice > 0) {
                    total += 40;
                }
                return total;
            },
            showPayment() {
                return !this.command.Pricing.IsFree && this.price > 0;
            },
            referenceRequired() {
                return this.command.MandatoryPo &&
                    !this.command.IsPrivate &&
                    this.command.PaymentType === 'invoice' &&
                    this.price > 0;
            },
            showReference() {
                return !this.command.IsPrivate &&
                    this.command.PaymentType === 'invoice' &&
                    this.price > 0;
            }
        },
        created() {
            const initial = JSON.parse(decodeURIComponent(this.initialData || '{}'));
            this.initialCommand = initial;
            Object.assign(this.command, initial);
            if (this.command.OrganizationNumber) {
                this.displayOptions.showCompanySearch = false;
                this.displayOptions.haveCompany = true;
            }
        },
        methods: {
            formSubmitting(evt) {
                if (this.invalid()) {
                    evt.preventDefault();
                }
            },
            updateCompanyFromBrReg(self, enhet) {
                const command = self.command;

                if (enhet.ExistingCompanyId > 0) {
                    command.SelectedExistingCompanyId = enhet.ExistingCompanyId;
                } else if (command.SelectedExistingCompanyId > 0 && !command.OrganizationNumber && command.Company) {
                    // Lager bare ny bedrift dersom valgt bedrift skrives veldig annerledes enn allerede tilknyttet
                    let avg = calculateSimilarity(enhet, command);
                    if (avg < .3) {
                        command.SelectedExistingCompanyId = 0;
                    }
                }

                command.Company = enhet.Name;
                if (!command.OrganizationNumber) {
                    command.OrganizationNumber = enhet.OrganizationNumber;
                }
                if (!command.BillingAddress && enhet.Address) {
                    command.BillingAddress = enhet.Address;
                    command.BillingPostalCode = enhet.PostalCode;
                    command.BillingPostalArea = enhet.PostalArea;
                }
                command.IsMember = enhet.IsMember;
                command.MandatoryPo = enhet.MandatoryPo;
                command.MandatoryCardPayment = enhet.MandatoryCardPayment;
                //self.referenceRequired = self.command.MandatoryPo;
                self.displayOptions.showCompanySearch = false;
                self.displayOptions.haveCompany = true;
            },
            selectCompany(enhet) {
                this.updateCompanyFromBrReg(this, enhet);
            },
            resetCompany() {
                this.command.Company = '';
                this.command.BillingAddress = '';
                this.command.BillingPostalCode = '';
                this.command.BillingPostalArea = '';
                this.command.OrganizationNumber = '';
                this.command.IsMember = false;
                this.displayOptions.showCompanySearch = true;
                this.displayOptions.haveCompany = false;
            },
            updateDiscount() {
                let self = this;
                this.http.post(`/umbraco/api/lookup/discount?discountCode=${this.command.DiscountCode}&eventId=${this.command.EventId}&attendees=${this.command.Attendees.length + 1}`)
                    .then(data => {
                        if (typeof (data.DiscountedPrice) === 'number') {
                            self.discountedPrice = data.DiscountedPrice;
                            self.discountMessage = null;
                        } else {
                            self.discountedPrice = 'none';
                            self.discountMessage = data.Message;
                        }
                    });
            }
        },
        /*
         * HER MÅ VI FINNE UT AV OM VI SKAL HA TYPEAHEAD / RULLEGARDIN / AUTOFYLL
         */
        watch: {
            companySearch(val) {
                const self = this;
                this.lastSearch = val;
                val = encodeURIComponent(val || '');
                if (val.length > 2) {
                    this.http
                        .post(
                            `/umbraco/api/lookup/companysearch?phrase=${val}&eventId=${self.command.EventId}`
                        )
                        .then(list => {
                            if (list && list.Phrase) {
                                self.companyMatches = list.Results;
                            }
                        })
                        .catch(e => { });
                } else {
                    self.companyMatches = [];
                }
            },
            'command.IsPrivate': function (newVal, oldVal) {
                if (this.processing) {
                    return;
                }
                this.displayOptions.showCompanySearch = !newVal;
                if (newVal === true && oldVal === false) {
                    this.command.IsMember = false;
                    this.command.MandatoryCardPayment = true;
                    this.displayOptions.haveCompany = false;
                }
                if (oldVal === true && newVal === false) {
                    this.resetCompany();
                }
            },
            'command.Email': function (val, valBefore) {
                if (/.+@.+\..{2,}/g.test(val)) {
                    if (val !== valBefore && valBefore !== '') {
                        this.resetCompany();
                    }
                    this.displayOptions.haveEmail = true;
                    this.currentEmail = val;
                    this.processing = true;

                    const url = `/umbraco/api/lookup/createenrollmentmodelfor?eventId=${this.command.EventId}&email=${this.command.Email}&pendingId=${this.command.ExistingEnrollmentId}`;

                    this.http
                        .post(url)
                        .then(data => {
                            if (this.command.Email === data.Email) {
                                Object.assign(this.command, data);

                                //this.referenceRequired = this.command.MandatoryPo;

                                if (this.command.OrganizationNumber || this.command.DisableCompanySearch) {
                                    this.displayOptions.showCompanySearch = false;
                                    this.displayOptions.haveCompany = true;
                                } else if (!this.command.IsPrivate) {
                                    console.log("dit not get it")
                                    if (this.command.Company) {
                                        this.companySearch = this.command.Company;
                                    }

                                    this.displayOptions.showCompanySearch = true;
                                    this.displayOptions.haveCompany = false;
                                }
                            }
                            this.processing = false;

                        })
                        .catch(e => {
                            this.processing = false;
                        });

                } else {
                    this.displayOptions.haveEmail = false;
                    this.displayOptions.showCompanySearch = true;
                    this.displayOptions.haveCompany = false;
                    this.command.FirstName = '';
                    this.command.LastName = '';
                }
            },
            'command.DiscountCode': function () {
                this.updateDiscount();
            },
            'command.Attendees': function () {
                this.updateDiscount();
            }
        }
    };
</script>
