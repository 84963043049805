﻿<template>
    <div class="toggle-wrapper">
        <button class="btn btn-default" @click="showItem = !showItem">
            {{ buttonName }}
        </button>
        <transition name="toggle">
            <div class="toggle-content-wrapper" v-show="showItem">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'toggle-wrapper',
    props: ['button-name'],
    data() {
        return {
            showItem: false
        };
    }
};
</script>
<style scoped>
.toggle-enter {
    opacity: 0;
}

.toggle-leave-active {
    opacity: 0;
}

.toggle-enter-active .toggle-content-wrapper,
.toggle-leave-active .toggle-content-wrapper {
    transition: opacity 0.5s;
    transform: scale(1.1);
}
</style>
