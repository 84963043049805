﻿<template>
    <div class="toggle-wrapper">
        <button
            :class="
                'btn btn-primary ' +
                    [classes ? classes : ''] +
                    [toggling ? ' toggling' : '']
            "
            @click="toggle"
        >
            <span v-if="icon" :class="icon"></span>
            {{ buttonName }}
        </button>
    </div>
</template>
<script>
export default {
    name: 'toggle-target',
    props: ['button-name', 'target', 'icon', 'classes', 'groupClass'],
    data() {
        return {
            toggleBox: {
                type: String,
                default: ''
            },
            toggling: false
        };
    },
    methods: {
        toggle() {
            if (this.toggleBox !== '' && this.toggleBox !== null) {
                const toggleBox = this.toggleBox;
                if (this.toggleBox.classList.contains('toggled')) {
                    this.hide(toggleBox);
                    this.toggling = false;
                } else {
                    this.toogleGroupClass();
                    this.show(toggleBox);
                    this.toggling = true;
                }
            }
        },
        show(element) {
            const height = this.getHeight(element);
            element.classList.add('toggled');
            element.style.height = height;
            setTimeout(function() {
                element.style.height = '';
            }, 350);
        },
        hide(element) {
            element.style.height = element.scrollHeight + 'px';
            setTimeout(function() {
                element.style.height = '0';
            }, 1);
            setTimeout(function() {
                element.classList.remove('toggled');
            }, 350);
        },
        getHeight(element) {
            element.style.display = 'block';
            const height = `${element.scrollHeight}px`;
            element.style.display = '';
            return height;
        },
        toogleGroupClass() {
            const groupedElements = document.querySelectorAll(
                '.' + this.groupClass
            );
            Array.from(groupedElements).forEach(e => {
                if (e.classList.contains('toggled')) {
                    this.hide(e);
                }
            });
        }
    },
    mounted() {
        if (this.target !== '') {
            this.toggleBox = document.querySelector(this.target);
            if (this.toggleBox !== null) {
                this.toggleBox.classList.add('toggle-item');
                if (this.groupClass !== '' && this.groupClass !== undefined) {
                    this.toggleBox.classList.add(this.groupClass);
                }
            }
        }
    }
};
</script>
<style>
.toggle-item {
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 350ms ease-in-out;
}

.toggle-item.toggled {
    display: block;
    height: auto;
}
</style>
